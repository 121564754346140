import _ from 'lodash';
import { createAction } from 'redux-actions';
import { getSearchQuery, getSearchQueryField } from '../../selectors/search';
import {
  isSingleVideo,
  isStripLayout,
  isVideoListAlwaysShow,
} from '../../selectors/app-settings';
import {
  getVideosPerPageCount,
  getCursor,
  getVideoIdsByPageNumber,
  getVideoIds,
  getCurrentPageIndex,
} from './selectors';
import { listPublicChannelVideos } from '../actions/channel/videos/list-public';
import { createProxy, createAsyncProxy } from '../../worker/lib';
import { getCurrentChannelId } from '../../selectors/channels';

export const RESET_LAZY_CHANNEL_VIDEOS = 'CLIENT.LAZY_CHANNEL_VIDEOS.RESET';
export const SET_LAZY_CHANNEL_VIDEOS_PAGE_INDEX =
  'CLIENT.LAZY_CHANNEL_VIDEOS.PAGE_INDEX.SET';
export const SET_LAZY_CHANNEL_VIDEOS_VIDEO_INDEX =
  'CLIENT.LAZY_CHANNEL_VIDEOS.VIDEO_INDEX.SET';
export const PREPEND_LAZY_VIDEO_IDS =
  'CLIENT.LAZY_CHANNEL_VIDEOS.PREPEND_VIDEO_IDS';

export const setLazyChannelVideosPageIndex = createAction(
  SET_LAZY_CHANNEL_VIDEOS_PAGE_INDEX,
);
export const setLazyChannelVideosVideoIndex = createAction(
  SET_LAZY_CHANNEL_VIDEOS_VIDEO_INDEX,
);
export const resetLazyChannelVideos = createAction(RESET_LAZY_CHANNEL_VIDEOS);
export const prependVideoIds = createAction(PREPEND_LAZY_VIDEO_IDS);

const PAGES_PER_REQUEST_COUNT = 1;

const loadMoreInChunks = createAsyncProxy(
  'loadMoreInChunks',
  (loadMoreNumber, { category, tag, query } = {}) =>
    async (dispatch, getState, { storage }) => {
      let itemsToLoad = loadMoreNumber;
      const state = getState();
      const videosPerPage = getVideosPerPageCount(state);

      while (itemsToLoad > 0) {
        const response = await storage.loadingPromise;
        if (response?.data?.result.length < videosPerPage) {
          return;
        }
        await dispatch(loadMore(videosPerPage, { category, tag, query }));
        itemsToLoad = itemsToLoad - videosPerPage;
      }

      storage.loadingPromise = null;
    },
);

export const loadMore = createAsyncProxy(
  'loadMore',
  (loadMoreNumber, { category, tag, query } = {}) =>
    (dispatch, getState, { storage }) => {
      const state = getState();
      const searchParams = _.omitBy({ query, tag, category }, _.isEmpty);

      storage.loadingPromise = (
        storage.loadingPromise || Promise.resolve()
      ).then(() => {
        const cursor = getCursor(state);

        if (cursor && storage.lastCursor === cursor) {
          return Promise.resolve();
        }

        storage.lastCursor = cursor;

        return dispatch(
          listPublicChannelVideos(getCurrentChannelId(state), {
            paging: {
              size: loadMoreNumber,
              cursor,
            },
            ...searchParams,
          }),
        );
      });

      return storage.loadingPromise;
    },
);

export const loadMoreVideoPages = createAsyncProxy(
  'loadMoreVideoPages',
  (pagesCount = PAGES_PER_REQUEST_COUNT) =>
    (dispatch, getState) => {
      const state = getState();

      if (isSingleVideo(state)) {
        return Promise.resolve();
      }

      const searchQuery = getSearchQuery(state);
      const searchQueryField = getSearchQueryField(state);
      const videosPerPage = getVideosPerPageCount(state);

      if (isStripLayout(state) && isVideoListAlwaysShow(state)) {
        pagesCount = 2;
      }

      const loadMoreFunction = pagesCount > 1 ? loadMoreInChunks : loadMore;

      return dispatch(
        loadMoreFunction(videosPerPage * pagesCount, {
          [searchQueryField]: searchQuery,
        }),
      );
    },
);

export const goToLazyVideosPageIndex = createProxy(
  'goToLazyVideosPageIndex',
  (pageIndex) => (dispatch, getState) => {
    const state = getState();
    const videoIdsByPageNumber = getVideoIdsByPageNumber(state);
    const cursor = getCursor(state);

    if (videoIdsByPageNumber.length === pageIndex + 1 && cursor) {
      dispatch(loadMoreVideoPages());
    }

    dispatch(setLazyChannelVideosPageIndex(pageIndex));
  },
);

export const loadMoreVideosForMobileVertical = createProxy(
  'loadMoreVideosForMobileVertical',
  () => (dispatch, getState) => {
    const state = getState();
    const cursor = getCursor(state);
    const currentPageIndex = getCurrentPageIndex(state);

    if (cursor) {
      dispatch(loadMoreVideoPages(1));
    }

    dispatch(setLazyChannelVideosPageIndex(currentPageIndex + 1));
  },
);

export const goToLazyVideoIndex = (videoIndex) => (dispatch, getState) => {
  const state = getState();
  const videoIds = getVideoIds(state);
  const cursor = getCursor(state);

  if (videoIds.length === videoIndex + 1 && cursor) {
    dispatch(loadMoreVideoPages());
  }

  dispatch(setLazyChannelVideosVideoIndex(videoIndex));
};
