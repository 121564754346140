import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './vertical.scss';
import { connect } from 'react-redux';
import { memoizedPartial } from '@wix/wix-vod-shared/common';
import { PictureMode } from '@wix/wix-vod-shared/components';
import CoverWithOverlay from '../../cover-with-overlay/cover-with-overlay';
import SlideMenu from '../../slide-menu/slide-menu';
import SlideMenuButton from '../../buttons/slide-menu-button';
import { loadMoreVideosForMobileVertical } from '../../../../../../redux/lazy-channel-videos/actions';
import {
  showAutoPlay,
  getVerticalVisibleVideos,
  getShareModalTopPosition,
} from '../../../../../../selectors/layout';
import {
  isPlayingOptimistic,
  isPausedOptimistic,
} from '../../../../../../selectors/playback';
import ActionsStrip from '../../actions-strip/actions-strip';
import LoadMoreButton from '../../load-more-button/load-more-button';
import NoVideosOverlay from '../../no-videos-overlay/no-videos-overlay';
import AutoPlayVideo from '../../../../../../components/autoplay-video/autoplay-video';
import {
  VERTICAL_LAYOUT_HEADER_HEIGHT,
  VERTICAL_LAYOUT_VIDEO_HEIGHT,
  VERTICAL_LAYOUT_VIDEO_MARGIN_BOTTOM,
  VERTICAL_LAYOUT_LOAD_MORE_BUTTON_HEIGHT,
} from '../../../../../../constants/sizes';

const mapStateToProps = (state) => ({
  isSlideMenuOpened: state.slideMenuOpened,
  visibleVideos: getVerticalVisibleVideos(state),
  selectedVideoId: state.selectedVideoId,
  showAutoPlay: showAutoPlay(state),
  windowSize: state.windowSize,
  playback: state.playback,
});

const mapDispatchToProps = {
  loadMoreVideosForMobileVertical,
};

export const Vertical = connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  class Vertical extends React.Component {
    static propTypes = {
      channelData: PropTypes.object.isRequired,
      windowSize: PropTypes.object.isRequired,
      playerSize: PropTypes.object.isRequired,
      mainVideo: PropTypes.object,

      visibleVideos: PropTypes.array.isRequired,

      selectedVideoId: PropTypes.string,

      isSlideMenuOpened: PropTypes.bool.isRequired,
      showAutoPlay: PropTypes.bool.isRequired,

      onSubscribeClick: PropTypes.func,
      showLogInForm: PropTypes.func.isRequired,
      openMemberOnlyPage: PropTypes.func.isRequired,
      onPlayRequestedDisallowed: PropTypes.func.isRequired,
      playVideo: PropTypes.func.isRequired,
      loadPlaybackModule: PropTypes.func.isRequired,
      pauseVideo: PropTypes.func.isRequired,
      loadMoreVideosForMobileVertical: PropTypes.func.isRequired,
    };

    static defaultProps = {
      onSubscribeClick: _.noop,
    };

    constructor(props) {
      super(props);

      this.state = {
        PlaybackComponent: null,
        isPlayerVisible: true,
      };

      props
        .loadPlaybackModule()
        .then((PlaybackComponent) => {
          this.setState({ PlaybackComponent });
        })
        .catch(_.noop);
    }

    playVideo = (videoItem) => {
      const { pauseVideo, playVideo, selectedVideoId } = this.props;

      if (selectedVideoId) {
        pauseVideo(selectedVideoId);
      }

      playVideo(videoItem);

      this.setState({ isPlayerVisible: true });
    };

    handleVideoClose = (id) => {
      this.props.pauseVideo(id);
      this.setState({ isPlayerVisible: false });
    };

    handleLoadMoreClick = () => {
      this.props.loadMoreVideosForMobileVertical();
    };

    renderHeader() {
      const { channelData } = this.props;

      return (
        <div
          className={styles.header}
          style={{ height: VERTICAL_LAYOUT_HEADER_HEIGHT }}
        >
          <span className={styles.channelLabel}>{channelData.title}</span>
          <SlideMenuButton className={styles.menuButton} />
        </div>
      );
    }

    renderMenu() {
      const { isSlideMenuOpened, channelData } = this.props;

      if (!isSlideMenuOpened) {
        return null;
      }

      // isFirstVideo is used in compact layout`s "share popup"
      // to determine which modal need to be opened: "Share Video" or "Share Channel".
      // In Vertical layout we always show "Share Channel", so this property hardcoded
      return (
        <SlideMenu
          showMenuButton={false}
          channelData={channelData}
          menuClassName={styles.menu}
          isFirstVideo
        />
      );
    }

    renderVideos() {
      const {
        windowSize,
        showLogInForm,
        openMemberOnlyPage,
        onPlayRequestedDisallowed,
        visibleVideos,
      } = this.props;

      return _.map(visibleVideos, (videoItem, videoIndex) => (
        <div
          key={videoItem.id}
          data-hook="mobile-vertical-video-item"
          className={styles.videoItem}
          style={{
            height: VERTICAL_LAYOUT_VIDEO_HEIGHT,
            marginBottom: VERTICAL_LAYOUT_VIDEO_MARGIN_BOTTOM,
          }}
        >
          <div className={styles.overlay}>
            <CoverWithOverlay
              videoItem={videoItem}
              itemWidth={windowSize.width}
              itemHeight={VERTICAL_LAYOUT_VIDEO_HEIGHT}
              isFirstVideo={false}
              onSignInRequested={showLogInForm}
              onPlayRequestedDisallowed={onPlayRequestedDisallowed}
              onPlayRequestedAllowed={memoizedPartial(
                this.playVideo,
                videoItem,
              )}
              onMemberSignUp={memoizedPartial(openMemberOnlyPage, videoItem.id)}
            />
          </div>
          {this.renderPlayer(videoIndex, videoItem)}
        </div>
      ));
    }

    renderPlayer(videoIndex, videoItem) {
      const {
        channelData,
        openMemberOnlyPage,
        playerSize: { width, height },
        playback,
        selectedVideoId,
      } = this.props;
      const { PlaybackComponent, isPlayerVisible } = this.state;
      const sharePopupTopPositon = getShareModalTopPosition(videoIndex);

      if (!PlaybackComponent || !videoItem) {
        return null;
      }

      const isVideoPlaying = isPlayingOptimistic({ playback }, videoItem.id);
      const isVideoPaused = isPausedOptimistic({ playback }, videoItem.id);
      const isSelected = videoItem.id === selectedVideoId;

      return (
        <div
          className={classnames(styles.player, {
            [styles.visible]:
              isSelected &&
              isPlayerVisible &&
              (isVideoPlaying || isVideoPaused),
          })}
        >
          <ActionsStrip
            videoItem={videoItem}
            sharePopupTopPositon={sharePopupTopPositon}
            channelData={channelData}
            className={styles.actionStrip}
            onCloseClick={memoizedPartial(this.handleVideoClose, videoItem.id)}
          />
          <PlaybackComponent
            videoItem={videoItem}
            channelData={channelData}
            width={width}
            height={height}
            onMemberSignUp={openMemberOnlyPage}
          />
        </div>
      );
    }

    renderLoadMoreButton() {
      const { visibleVideos, channelData } = this.props;

      return visibleVideos.length === channelData.videosCount ? null : (
        <LoadMoreButton
          height={VERTICAL_LAYOUT_LOAD_MORE_BUTTON_HEIGHT}
          onClick={this.handleLoadMoreClick}
        />
      );
    }

    render() {
      const { channelData, showAutoPlay } = this.props;

      if (!channelData.videosCount) {
        return <NoVideosOverlay title={channelData.title} />;
      }

      return (
        <div data-hook="mobile-vertical-layout" className={styles.layout}>
          {this.renderHeader()}
          {this.renderMenu()}
          {this.renderVideos()}
          {this.renderLoadMoreButton()}
          {showAutoPlay && <AutoPlayVideo isMobile />}
        </div>
      );
    }
  },
);
